import * as React from "react"
import { graphql } from 'gatsby'

import Layout from "../components/layout"
import Seo from "../components/seo"
import TitleBlock from "../components/titleBlockPages"
import FullWidthCTA from "../components/fullWidthCTABlock"
import TKingMap from "../components/tking-map"
import ContactForm from "../components/footerContactBlock"

const ContactPage = ({ data }) => {
  ///page details
  const pageDataEdges = data.allPagecontactJson.edges[0].node;
  return (
  <Layout>
      <Seo
        title="Contact Us Now For A Quote On Customisable Products "
        description="Get in touch with us to see how we can help create customisable products for your business or clients. Talk to us now >>"
      />
    <TitleBlock
        pageTitle={pageDataEdges.pageTitle}
        pageSubTitle={pageDataEdges.pageSubTitle}
        pageTitleBody={pageDataEdges.pageTitleBody}
        img={pageDataEdges.pageTitleImg}
      />
    <FullWidthCTA
      lsH3={'We pride ourselves on giving the most practical, straightforward advice to get the most polished, professional result.'}
      lsBody={''}
      rsH3={""}
      rsBody={"We can guide and advise on a number of expert areas, from screen and digital printing to embroidery. And our in-house design team is always prepped and ready to enhance your branding and ensure the best possible finish and performance quality. "}
    />
    <TKingMap />
    <ContactForm/>
    </Layout>
  )
}
export const query = graphql`
  query MyQuery {
    
    
    allPagecontactJson {
      edges {
        node {
          pageTitleBody
          pageTitle
          pageSubTitle
          pageTitleImg {
                childImageSharp {
                    gatsbyImageData(
                      width: 1600
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                }
              }
        }
      }
    }
}`
export default ContactPage
