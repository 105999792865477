import React from "react";
import GoogleMapReact from 'google-map-react';

import { MapPin } from 'react-feather';

export default function SimpleMap(){
  const defaultProps = {
    center: {
      lat: 51.989135,
      lng: -0.9918339
    },
    zoom: 18
  };

  return (
    // Important! Always set the container height explicitly
    <div
      className="mapWrapper"
      style={{ minHeight: '500px', maxHeight: '800px', height: '50vh', width: '100%' }}
      data-sal-duration="500"
      data-sal="slide-up"
      data-sal-delay="200"
      data-sal-easing="ease"
    >
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyCHGLqriGaeeEttUvrc0a8vH5tjU2GVaRc" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
  
          <MapPin
            lat={51.989135}
            lng={-0.9918039}
            size={50} 
            style={{
              transform: 'translate(-50%,-100%)',
              fill:'#FFDE06'
            }}
            className="text-secondary "
          />
          
     
      </GoogleMapReact>
    </div>
  );
}